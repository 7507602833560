@import "normalize.css";
@import "sanitize.css";

html, body, #root, main {
    height: 100%
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(359deg);
    }
}